import React from "react";
import Presentation from "../../components/Presentation";

function Home() {
  return (
    <section>
      <Presentation />
    </section>
  );
}

export default Home;
