import React from "react";

function Footer() {
  return (
    <footer className="w-full flex justify-center items-center">
      Jose Martinez © copyright
    </footer>
  );
}

export default Footer;
