export const sideBarElement = [
  {
    _id: 1,
    name: "home",
    route: "/",
  },
  {
    _id: 2,
    name: "aboutMe",
    route: "/aboutMe",
  },
  {
    _id: 3,
    name: "contact",
    route: "/contact",
  },
];
